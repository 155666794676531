.App {
  color: #346250;
  background-color: #346250;
  text-align: center;
  height: 100vh;
  width:  100%;
  margin:  0;
  display: flex;
  flex-direction: column;
}

.home, .midscreen {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}
